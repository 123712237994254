import { Badge, Card, CopyButton, Text, TextInput, Flex, ActionIcon } from "@mantine/core";
import { useStyles } from "../../styles";
import { IconCopy, IconCheck } from "@tabler/icons";

export function ProjectInfo({ project, apiUrl }: {project: any, apiUrl: string}) {
  const { classes } = useStyles();

  return (
    <Card withBorder p="xl" radius="md" className={classes.card}>
      <Text fz="xl" className={classes.label}>
        Credentials
      </Text>
      {(project.network.slug === "tron-mainnet" || project.network.slug === "tron-nile") && (
        <>
          <div>
            <Badge>url</Badge>: {apiUrl}
          </div>
          <div>
            <Flex>
              <Badge>token</Badge>: <span>{project.token}</span>
              <CopyButton value={`${apiUrl}${project.token}/`}>
                {({ copied, copy }) => (
                  <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                  {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                  </ActionIcon>
                )}
              </CopyButton>
            </Flex>
          </div>
        </>
      )}
      {(project.network.slug === "ethereum-mainnet" || project.network.slug === "arbitrum-one" || project.network.slug === "blast-mainnet") && (
        <>
          <div>
            <Badge>Http provider</Badge>:
            <Flex my="md" direction="row" justify="center" align="center" style={{width: "100%"}}>
              <TextInput style={{width: "100%"}} readOnly={true} value={`${apiUrl}${project.token}/`} />
              <CopyButton value={`${apiUrl}${project.token}/`}>
                {({ copied, copy }) => (
                  <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                  {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                  </ActionIcon>
                )}
              </CopyButton>
            </Flex>
          </div>
          <div>
            <Badge>Token</Badge>:
            <Flex my="md" direction="row" justify="center" align="center" style={{width: "100%"}}>
              <TextInput style={{width: "100%"}} readOnly={true} value={project.token} />
              <CopyButton value={project.token}>
                {({ copied, copy }) => (
                  <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                  {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                  </ActionIcon>
                )}
              </CopyButton>
            </Flex>
          </div>
        </>
      )}
    </Card>
  )
}