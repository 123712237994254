import { gql } from '@apollo/client';
import { CORE_USER_FIELDS } from './fragments';


export const ME = gql`
  ${CORE_USER_FIELDS}
  query me {
    me {
      ...CoreUserFields
    }
  }
`;

export const GET_NETWORKS = gql`
  query getNetworks {
    networks {
      slug
      title
      status
    }
  }
`;

export const GET_DASHBOARD = gql`
  query getDashboard {
    networks {
      slug
      title
      status
    }

    plans {
      id
      slug
      createdAt
      updatedAt
      requestsPerMonth
      rateLimit
      ratePeriod
      title
      description
      visibility
      price
      currency
    }
  }
`;

export const GET_PROJECTS = gql`
  query getProjects {
    projects {
      list {
        id
        name
        token
        createdAt
        plan {
          id
          slug
          createdAt
          updatedAt
          requestsPerMonth
          rateLimit
          ratePeriod
        }
        network {
          slug
          title
          headerInDomain
          headerInPath
        }
        currentStats {
          total
        }
      }
      count
    }
  }
`;


export const GET_PROJECT = gql`
  query getProject($projectId: Int!, $begin: DateTime!, $end: DateTime!, $begin2: DateTime!) {
    project(projectId: $projectId) {
      id
      name
      token
      createdAt
      plan {
        id
        slug
        createdAt
        updatedAt
        requestsPerMonth
        rateLimit
        ratePeriod
      }
      network {
        slug
        title
        domain
        headerInDomain
      }
      currentStats {
        total
      }
    }

    getStats(projectId: $projectId, end: $end, begin: $begin) {
      date
      count
    }

    dayStats: getStats(projectId: $projectId, end: $end, begin: $begin2, group: day) {
      date
      count
    }
  }
`;


export const GET_PLANS = gql`
  query getPlans {
    plans {
      id
      slug
      createdAt
      updatedAt
      requestsPerMonth
      rateLimit
      ratePeriod
      title
      description
      visibility
      price
      currency
    }
  }
`;


export const GET_ADMIN_USERS = gql`
  query getAdminUsers($limit: Int!, $offset: Int!, $isPayed: Boolean) {
    adminUsers(limit: $limit, offset: $offset, isPayed: $isPayed) {
      list {
        id
        email
        active
        data {
          planEndDate
          planSlug
          billingBeginDate
          blockedTill
          telegramUsername
        }
        firstName
        isAdmin
        lastName
        verified
        currentStats {
          begin
          total
          end
        }
        insertInstant
      }
      count
    }
    adminPlans {
      id
      slug
      createdAt
      updatedAt
      requestsPerMonth
      rateLimit
      ratePeriod
      title
      description
      visibility
      price
      currency
    }
  }
`;

export const GET_ADMIN_PLANS = gql`
  query getPlans {
    adminPlans {
      id
      slug
      createdAt
      updatedAt
      requestsPerMonth
      rateLimit
      ratePeriod
      title
      description
      visibility
      price
      currency
    }
  }
`;


export const GET_ADMIN_NETWORKS = gql`
  query AdminNetworks {
    adminNetworks {
      slug
      title
      domain
      headerInDomain
      headerInPath
      createdAt
      updatedAt
      status
    }
  }
`;


export const GET_ADMIN_PROJECTS = gql`
  query getProjects($limit: Int!, $offset: Int!)  {
    adminProjects(limit: $limit, offset: $offset) {
      list {
        id
        userId
        user {
          email
          fullName
        }
        name
        token
        createdAt
        plan {
          id
          slug
          createdAt
          updatedAt
          requestsPerMonth
          rateLimit
          ratePeriod
        }
        network {
          slug
          title
          headerInDomain
          headerInPath
        }
        currentStats {
          total
        }
        status
      }
      count
    }
    networks {
      slug
      title
    }
  }
`;
