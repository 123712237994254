import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useForm } from '@mantine/form';
import { DateTimePicker } from '@mantine/dates';

import { Modal, FocusTrap, Text, Button, Box, Select, Table, Card, Container, Anchor, ActionIcon, ScrollArea, Group, Skeleton, Pagination, Badge, Checkbox, TextInput } from '@mantine/core';
import { IconCircleCheck, IconFilePencil, IconUserCircle } from '@tabler/icons';
import { GET_ADMIN_USERS } from '../../../graphql/queries';
import { EDIT_ADMIN_USER_PLAN } from '../../../graphql/mutations';
import { Error } from '../../../Components/Error';
import { Link } from 'react-router-dom';
import { DateTime } from "luxon";


const DATE_FORMAT = "yyyy/LL/dd"

type UserDataType = {
  planSlug: string,
  planEndDate: Date | string,
  billingBeginDate: Date | string
  blockedTill: Date | string
  telegramUsername: string | null
}

export function AdminUsersPage() {
  const perPage = 25;
  const [page, setPage] = useState(1);
  const [payed, setPayed] = useState(false);

  const { loading, error, data } = useQuery(GET_ADMIN_USERS, {
    variables: {
      offset: (page - 1) * perPage,
      limit: perPage,
      isPayed: payed
    }
  });
  const [editPlanUserId, setEditPlanUserId] = useState("");
  let rows = null;

  const [editPlan, editPlanData] = useMutation(EDIT_ADMIN_USER_PLAN, {
    refetchQueries: [
      {
        query: GET_ADMIN_USERS,
        variables: {
          offset: (page - 1) * perPage,
          limit: perPage
        }
      }
    ],
    onCompleted() {
      setEditPlanUserId("");
      formPlan.reset();
    }
  });

  const openEditPlan = (userId: string, user: any) => {
    setEditPlanUserId(userId);

    formPlan.setValues({
      planSlug: user.data.planSlug,
      planEndDate: user.data.planEndDate ? new Date(user.data.planEndDate) : "",
      billingBeginDate: user.data.billingBeginDate ? new Date(user.data.billingBeginDate) : "",
      blockedTill: user.data.blockedTill ? new Date(user.data.blockedTill) : "",
      telegramUsername: user.data.telegramUsername
    })
  };

  const formPlan = useForm<UserDataType>({
    initialValues: {
      planSlug: "",
      planEndDate: "",
      billingBeginDate: "",
      blockedTill: "",
      telegramUsername: ""
    },
  });

  const doEditPlan = () => {
    let values: any = formPlan.values;
    values["userId"] = editPlanUserId;

    if (!values["planEndDate"]) {
      values["planEndDate"] = null;
    }

    if (!values["billingBeginDate"]) {
      values["billingBeginDate"] = null;
    }

    if (!values["blockedTill"]) {
      values["blockedTill"] = null;
    }

    if (!values["telegramUsername"]) {
      values["telegramUsername"] = null;
    }

    console.log("trs", values);

    editPlan({
      variables: values
    })
  };

  const closeAddPlanDialog = () => {
    setEditPlanUserId("");
  }

  let plans = [];
  if (data) {
    console.log(data);
    rows = data.adminUsers.list.map((row: any) => {
      return (
        <tr key={row.id}>
          <td>
            <Anchor
              component={Link}
              target="_blank"
              to={`https://fusionauth.tronql.com/admin/user/manage/${row.id}`}
            >
              {row.email}
            </Anchor>
            <Text color="dimmed">{row.id}</Text>
            <Text color="dimmed">Created: {DateTime.fromISO(row.insertInstant).toFormat(DATE_FORMAT)}</Text>
            {row.data.telegramUsername && <Text color="dimmed"><IconUserCircle size={16} stroke={1.5} />{row.data.telegramUsername}</Text>}
          </td>
          <td>
            {row.active ? <IconCircleCheck /> : "-"}
            {row.verified ? <IconCircleCheck /> : "-"}
            {row.isAdmin ? <IconCircleCheck /> : "-"}
          </td>
          <td>
            {row.data.planSlug}
            {row.data.planEndDate && (
              <div>
                End date: {DateTime.fromISO(row.data.planEndDate).toFormat(DATE_FORMAT)}
              </div>
            )}
            {row.data.blockedTill && (
              <div>
                Blocked: {DateTime.fromISO(row.data.blockedTill).toFormat(DATE_FORMAT)}
              </div>
            )}
          </td>
          <td>
            {row.data.billingBeginDate && (
              <>
                {DateTime.fromISO(row.data.billingBeginDate).toFormat(DATE_FORMAT)}
              </>
            )}
          </td>
          <td>
            {row.currentStats && (
              <>
                <div style={{whiteSpace: "nowrap"}}>
                  <Badge>{DateTime.fromISO(row.currentStats.begin).toFormat(DATE_FORMAT)}</Badge> -
                  <Badge>{DateTime.fromISO(row.currentStats.end).toFormat(DATE_FORMAT)}</Badge>
                </div>
                <div>Total: {row.currentStats.total.toLocaleString()}</div>
              </>
            )}
          </td>
          <td>
            <Group spacing={0} position="right">
              <ActionIcon onClick={() => openEditPlan(row.id, row)} color="green">
                <IconFilePencil size={16} stroke={1.5} />
              </ActionIcon>
            </Group>
          </td>
        </tr>
      );
    });
    for (var plan of data.adminPlans) {
      plans.push(plan.slug);
    }
  }
  return (
    <ScrollArea>
      <Modal opened={!!editPlanUserId} title="Add new plan" withCloseButton onClose={closeAddPlanDialog} size="lg" radius="md">
        <Box mih={400}>
          <FocusTrap active={!!editPlanUserId}>
            <Error text={editPlanData?.error?.toString()} />
            <DateTimePicker
              label="Plan end date"
              valueFormat="DD MMM YYYY hh:mm A"
              placeholder="Pick date and time"
              {...formPlan.getInputProps('planEndDate')}
              clearable
            />
            <DateTimePicker
              label="Billing begin date"
              valueFormat="DD MMM YYYY hh:mm A"
              placeholder="Pick date and time"
              {...formPlan.getInputProps('billingBeginDate')}
            />
            <DateTimePicker
              label="Blocked till date"
              valueFormat="DD MMM YYYY hh:mm A"
              placeholder="Pick date and time"
              {...formPlan.getInputProps('blockedTill')}
              clearable
            />
            <TextInput label="Telegram username" placeholder="Telegram username" {...formPlan.getInputProps('telegramUsername')} />
            <Select label="Plan" placeholder="" data={plans} {...formPlan.getInputProps('planSlug')} />
            <Button color={"green"} loading={editPlanData.loading} onClick={doEditPlan}>Save</Button>
          </FocusTrap>
        </Box>
      </Modal>

      { loading ? (
        <>
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
          <Skeleton height={8} mt={6} radius="xl" />
        </>
      ) : (
        <>
        { error ? (
          <Error text={error?.toString()} />
        ) : (
          <>
            <Group align="flex-end">
              <h2 style={{ flex: 1 }} >Users, total {data.adminUsers.count}</h2>
            </Group>

            <Group>
              Filters: <Checkbox checked={payed} onChange={(event) => setPayed(event.currentTarget.checked)}>Payed</Checkbox>
            </Group>

            <Container>
              { rows && rows.length ? (
                <Table sx={{ minWidth: 800 }} verticalSpacing="xs">
                  <thead>
                    <tr>
                      <th>Email/UserId</th>
                      <th>Active/Verified/Admin</th>
                      <th>Plan/Expired/Blocked&nbsp;Till</th>
                      <th>Billing start</th>
                      <th>Stats</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </Table>
              ) : (
                <Card>No users.</Card>
              )}
              <Pagination value={page} total={Math.ceil(data.adminUsers.count / perPage)} onChange={setPage} />
            </Container>
          </>
        )}
        </>
      )}
    </ScrollArea>
  );
}