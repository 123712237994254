import TronIcon from '../assets/logos/tron.png';
import EthereumIcon from '../assets/logos/ethereum.png';
import ArbitrumIcon from '../assets/logos/arbitrum.png';
import BlastIcon from '../assets/logos/blast.png';


export function NetworkLogo({network, size} : {network: string, size: number}) {
  let title = "";
  let logo;

  if (network === "tron-mainnet") {
    title = "Tron mainnet";
    logo = TronIcon;
  } else if (network === "tron-nile") {
    title = "Tron nile";
    logo = TronIcon;
  } else if (network === "ethereum-mainnet") {
    title = "Ethereum mainnet";
    logo = EthereumIcon;
  } else if (network === "arbitrum-one") {
    title = "Arbitrum one";
    logo = ArbitrumIcon;
  } else if (network === "blast-mainnet") {
    title = "Blast mainnet";
    logo = BlastIcon;
  }

  return (
    <img
      width={size}
      height={size}
      src={logo}
      alt={title}
      data-network={network}
      />
  );
}